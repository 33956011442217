<template>
  <div class="block-wrapper">
    <a-form @submit.prevent="handleSubmit">
      <a-form-item label="ID">
        <a-input read-only :value="request.id" />
      </a-form-item>

      <a-form-item label="Статус">
        <a-checkbox v-model="request.is_active">
          Активный
        </a-checkbox>
      </a-form-item>

      <a-form-item label="Состояние">
        <a-tag :color="tagColorByState[request.state]">
          {{ textByState[request.state] }}
        </a-tag>
      </a-form-item>

      <a-form-item label="Сумма займа">
        <a-input read-only :value="formatMoney(request.borrow)" />
      </a-form-item>

      <a-form-item label="Ref ID">
        <a-input read-only :value="request.reference_id" />
      </a-form-item>

      <a-form-item label="Почта клиента">
        <a-input read-only :value="request.client && request.client.email" />
      </a-form-item>

      <a-form-item label="Клиент">
        <a-input
          read-only
          :value="
            request.client &&
              `${request.client.first_name} ${request.client.last_name}`
          "
        />
      </a-form-item>

      <a-form-item label="Дата создания">
        <a-input
          :value="new Date(request.created_at).toLocaleString('ru-RU')"
          read-only
        />
      </a-form-item>
      <a-form-item label="Дата последнего изменения">
        <a-input
          :value="new Date(request.updated_at).toLocaleString('ru-RU')"
          read-only
        />
      </a-form-item>
      <a-collapse :style="{ marginBottom: '15px' }">
        <a-collapse-panel
          v-if="request.response"
          header="Ответ сервиса Autoconvert"
        >
          <prism language="json">
            {{ request.response }}
          </prism>
        </a-collapse-panel>
      </a-collapse>

      <a-form-item :style="{ display: 'flex', justifyContent: 'flex-end' }">
        <a-button @click.prevent="$router.go(-1)">
          Назад
        </a-button>
        <a-button
          :style="{ marginLeft: '15px' }"
          type="primary"
          html-type="submit"
        >
          Сохранить
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import 'prismjs'
import 'prismjs/components/prism-json'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import { formatMoney } from '@/utils'
import { GET_REQUEST } from '@/store/types'
import { showNotification } from '@/utils'

export default {
  components: { Prism },

  data() {
    return {
      request: {},
      textByState: {
        declined: 'Отклонён',
        approved: 'Одобрен',
        processing: 'Ожидает',
      },
      tagColorByState: {
        declined: 'red',
        approved: 'green',
        processing: 'orange',
      },
    }
  },

  async created() {
    this.getRequest()
  },

  methods: {
    formatMoney,

    async getRequest() {
      try {
        const request = await this.$store.dispatch(
          GET_REQUEST,
          this.$route.params.id,
        )

        this.request = request
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
</script>
